.updown {
  margin: .02em 0 0 .1em;
  height: .64em;
  fill: currentColor;
}

.updown.up {
  fill: #093;
}

.updown.down {
  transform: rotate(180deg) translateY(.05em);
  fill: #ff333a;
}