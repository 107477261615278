.container {
  position: fixed;
  height: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.notification {
  background-color: #525358;
  border-radius: .5em;
  box-shadow: 0 10px 20px rgba(0,0,0,.25);
  padding: 1em;
  margin: 0 auto;
  display: flex;
  align-items: center;
  transform: translateY(-250px);
  max-height: calc(250px - 2em);
  max-width: min(calc(540px - 4em), calc(100vw - 3em));
  transition: transform .4s ease-out;
}

.notification.visible {
  transform: translateY(.6em);
}

@supports (-webkit-backdrop-filter: blur(5px)) {
  .notification {
    backdrop-filter: blur(5px) saturate(3);
    -webkit-backdrop-filter: blur(5px) saturate(3);
  }
}

.icon {
  flex: 0 0 48px;
  height: 48px;
  width: 48px;
  margin-right: 1em;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.success {
  border: 1px solid #093;
  background-image: url("data:image/svg+xml;utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 70'><polyline stroke='%23093' fill='transparent' stroke-width='2' points='23 34 34 43 47 27' /></svg>");
}

.warning {
  background-image: url("data:image/svg+xml;utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 263.65 263.65' fill='orange'><path d='M262.846,237.792L137.021,19.858c-1.072-1.856-3.053-3-5.196-3s-4.125,1.144-5.196,3L0.804,237.792 c-1.072,1.856-1.072,4.144,0,6s3.053,3,5.196,3H257.65c2.144,0,4.125-1.144,5.196-3S263.918,239.649,262.846,237.792z M16.392,234.792L131.825,34.858l115.433,199.935H16.392z' /><path d='M121.491,106.734l4.333,76.404c0.167,3.013,2.576,5.485,5.66,5.66c3.314,0.188,6.152-2.346,6.34-5.66l4.333-76.404 c0.021-0.383,0.022-0.78,0-1.172c-0.324-5.707-5.213-10.071-10.919-9.747S121.168,101.027,121.491,106.734z' /><path d='M131.825,201.915c-1.58,0-3.13,0.64-4.24,1.76c-1.12,1.11-1.76,2.66-1.76,4.24s0.64,3.13,1.76,4.24 c1.11,1.12,2.66,1.76,4.24,1.76s3.13-0.64,4.24-1.76c1.12-1.11,1.76-2.66,1.76-4.24s-0.64-3.13-1.76-4.24 C134.955,202.555,133.405,201.915,131.825,201.915z' /></svg>");
  background-size: 60%;
  background-position: 50% 40%;
}

.message {
  flex: 1;
}