.form {
  position: fixed;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1em;
  background: linear-gradient(-18deg, #252627 60%, #525358 100%);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  .form {
    /* The hack for Safari */
    height: -webkit-fill-available;
  }
}

.form::before {
  content: "";
  display: block;
  position: absolute;
  top: 25vh;
  left: -10vw;
  background: linear-gradient(-18deg, #252627 6%, #525358 100%);
  transform: skewY(-18deg) scaleY(1) translateY(0);
  width: 120vw;
  height: 65vh;
  z-index: 1;
}

.page {
  position: relative;
  z-index: 2;
  max-width: 540px;
  border-radius: .5em;
  display: flex;
  flex-direction: column;
  min-height: min(620px, calc(100vh - 76px));
  max-height: calc(100vh - 100px);
  width: 100vw;
  box-shadow: 0 5px 30px rgba(0,0,0,.3);
  background-color: #fff;
  animation: fade .2s ease-out 1;
  opacity: 1;
  transform: translateY(20px) scale(1);
}

.form.swipingDown {
  background-image: linear-gradient(-18deg, #252627 60%, #525358 100%);
  transition: all .2s ease-out;
}

.form.swipingDown::before {
  background-image: linear-gradient(-18deg, #252627 6%, #525358 100%);
  transition: all .2s ease-out;
}

.swipingDown .page {
  transform: translateY(12px) scale(0.9);
  transition: all .15s ease-out;
  opacity: 0;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(32px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(20px) scale(1);
  }
}

@media (max-width: 680px) {
  .form {
    background: #383b43;
  }

  .body {
    transition: none;
    flex-basis: 333px;
  }

  .page {
    position: absolute;
    top: 62px;
    border-radius: 1em 1em 0 0;
    max-width: unset;
    max-height: unset;
    transform-origin: 50% 200%;
    height: calc(100vh - 62px);
    animation: fadeMobile .2s ease-out 1;
  }

  /* .page::before {
    content: "";
    position: absolute;
    width: 100px;
    border-radius: 2px;
    height: 4px;
    top: -9px;
    left: 50%;
    margin: 0 -50px;
    background: rgba(255,255,255,.5);
  } */

  .swipingDown .page {
    transform: translateY(100vh);
    transition: all .15s ease-out;
    opacity: 1;
  }

  @keyframes fadeMobile {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }
}

.header {
  flex: 0;
  padding: 1em 1em .4em;
  align-self: center;
}

.header button {
  position: absolute;
  left: 1em;
  top: 17px;
  border: 0 !important;
  padding: 0;
  width: 35px;
  height: 34px;
  text-align: center;
}

.header button {
  display: none;
}

.header button svg {
  width: 1.66em;
  height: 34px;
}

.header button path {
  transition: all .1s linear;
  transform: rotate(0);
  opacity: 1;
}

.header button.close,
.header button.back {
  display: block;
}

.header button.close .top {
  transform: rotate(45deg) translate(7px, -9px);
}

.header button.close .bottom {
  transform: rotate(-45deg) translate(-15px, -1px);
}

.header button.back .top {
  transform: rotate(45deg) translate(15px, -5px) scaleX(.6);
}

.header button.back .bottom {
  transform: rotate(-45deg) translate(-6px, -6px) scaleX(.6);
}

.body {
  flex: 1 1;
  color: #222;
  position: relative;
  overflow: hidden;
  transition: all 1.5s ease-out;
}

.label {
  position: absolute;
  top: 1.48em;
  left: 3.7em;
  color: #bca36c;
}

.action {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
}

button.next {
  min-width: 120px;
}

.stepOuter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateX(-100px);
  transition: all .2s ease-out;
  opacity: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
}

.stepOuter.active {
  transform: translateX(0);
  opacity: 1;
  z-index: 2;
}

.stepOuter.active + .stepOuter,
.stepOuter.active + .stepOuter + .stepOuter,
.stepOuter.active + .stepOuter + .stepOuter + .stepOuter,
.stepOuter.active + .stepOuter + .stepOuter + .stepOuter + .stepOuter,
.stepOuter.active + .stepOuter + .stepOuter + .stepOuter + .stepOuter + .stepOuter {
  transform: translateX(100px);
}

.step {
  padding: 1em 4em 4em;
}

.step a {
  margin: -.2em;
  padding: .2em .2em;
}

@media (max-width: 420px) {
  .step {
    padding: 2px 1.5em 8em;
  }
}

