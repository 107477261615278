.container {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 1001;
}

.supportbutton {
  position: absolute;
  bottom: 1em;
  right: 1em;
  padding: .4em .7em .45em;
}

.supportbutton svg {
  vertical-align: -25%;
  fill: #fff;
}

.supportbutton span {
  margin-left: .5em;
}

.closebutton {
  float: right;
  padding: .3em .8em;
  margin: -.35em -.1em;
}

.popup {
  position: absolute;
  width: 270px;
  bottom: 4.5em;
  right: 1em;
  padding: .4em 1em 1em;
  border-radius: .5em;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0,0,0,.25);
  color: #000;
  transform: translateY(20px);
  opacity: 0;
  transition: all .2s ease-out;
}

.visible .popup {
  transform: translateY(0px);
  opacity: 1;
}
