.loading {
  display: inline-block;
  position: relative;
  margin: 0 .7em;
  width: .5em;
  height: .5em;
  line-height: 1em;
  vertical-align: 5%;
  border-radius: 50%;
  background-color: rgba(154, 154, 154, .9);
  color: rgba(154, 154, 154, .9);
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.loading::before, .loading::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.loading::before {
  left: -.75em;
  width: .5em;
  height: .5em;
  border-radius: 5px;
  background-color: rgba(154, 154, 154, .9);
  color: rgba(154, 154, 154, .9);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.loading::after {
  left: .75em;
  width: .5em;
  height: .5em;
  border-radius: 5px;
  background-color: rgba(154, 154, 154, .9);
  color: rgba(154, 154, 154, .9);
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: rgba(154, 154, 154, .9);
  }
  50%, 100% {
    background-color: rgba(154, 154, 154, .2);
  }
}



/* .loading:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  border-radius: .3em;
  background-size: 400px 100px;
  background-color: rgba(154,154,154,.1);
  background-image: linear-gradient(109deg, rgba(154,154,154,.25) 10%, rgba(154,154,154,0) 20%, rgba(154,154,154,0) 50%, rgba(154,154,154,.25) 95%);
  animation: flow 1s linear infinite;
}
  
@keyframes flow {
  0% {
    background-position-x: -400px;
    background-size: 400px 100px;
  }
  50% {
    background-size: 480px 100px;
  }
  100% {
    background-position-x: 0px;
    background-size: 400px 100px;
  }
}
*/